import * as Yup from 'yup';

export const validationSchema = ({ intl }) => {
  const requiredMessage = intl.formatMessage({ id: 'required' });
  const emailMessage = intl.formatMessage({ id: 'invalidEmail' });

  return Yup.object().shape({
    customerName: Yup.string().required(requiredMessage),
    customerId: Yup.string().nullable(),
    abn: Yup.string()
      .required(requiredMessage)
      .min(11, 'Must be exactly 11 digits')
      .max(11, 'Must be exactly 11 digits'),
    anzsciCode: Yup.string().required(requiredMessage),
    segmentCode: Yup.string().required(requiredMessage),
    ratesheet: Yup.string().required(requiredMessage),
    firstName: Yup.string().required(requiredMessage),
    surname: Yup.string().required(requiredMessage),
    email: Yup.string().required(requiredMessage).email(emailMessage),
  });
};

export const initialValues = {
  customerName: '',
  customerId: '',
  abn: '',
  anzsciCode: '',
  segmentCode: '',
  ratesheet: '',
  firstName: '',
  surname: '',
  email: '',
};

export const segmentCodeOptions = [
  { label: 'Bank', value: 'BANK' },
  { label: 'Local Gov.', value: 'LOCALGOV' },
  { label: 'Fund', value: 'FUND' },
  { label: 'Corp', value: 'CORP' },
  { label: 'Family Office', value: 'FAMILY_OFFICE' },
  { label: 'Government', value: 'GOVERNMENT' },
  { label: 'Not For Profit', value: 'NOT_FOR_PROFIT' },
  { label: 'University', value: 'UNIVERSITY' },
];

export const ratesheetOptions = [
  { label: 'Default', value: 'DEFAULT' },
  { label: 'Bespoke', value: 'BESPOKE' },
];
