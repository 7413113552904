import React, { useRef, useState, useMemo } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row } from 'react-display-flex';
import HotTable from 'react-handsontable';
import { Button } from 'react-bootstrap';
import { FaPlus, FaArrowLeft } from 'react-icons/fa';

import { segmentCodeOptions } from '../CreateSingleProposedTenantDrawer/create-single-proposed-tenant-schema';
import imperiumLogo from '../../../assets/icons/common/logo_white.png';
import { Drawer, Column, Popover, ConfirmationPopover } from '../../../components/common';
import { createProposedTenant } from '../../../api/onboarding/create-proposed-tenant';
import { MessageType, showToastMessage } from '../../toast/toast';
import './create-proposed-tenant-drawer.scss';

const fileReader = new FileReader();
const initialErrors = [];

export const CreateMultiProposedTenantDrawer = injectIntl(
  ({ open, onToggle, tenantDomain, onSuccess, anzsciCodes, intl }) => {
    const tableRef = useRef();
    const [errors, setErrors] = useState(initialErrors);
    const [fileRows, setFileRows] = useState();
    const [isDragActive, setIsDragActive] = useState(false);

    const tableHeaders = useMemo(
      () => [
        { data: 'customerName', label: 'Customer name', validator: notEmptyValidator },
        { data: 'customerId', label: 'Customer ID' },
        { data: 'abn', label: 'ABN', validator: notEmptyValidator },
        {
          data: 'anzsciCode',
          label: 'ANZSIC CODE',
          type: 'autocomplete',
          source: anzsciCodes?.map(({ value }) => value),
          strict: true,
        },
        {
          data: 'segmentCode',
          label: 'Segment',
          type: 'dropdown',
          source: segmentCodeOptions.map(({ value }) => value),
        },
        { data: 'firstName', label: 'First name', validator: notEmptyValidator },
        { data: 'surname', label: 'Surname', validator: notEmptyValidator },
        { data: 'email', label: 'E-mail', validator: notEmptyValidator },
        {
          data: 'ratesheet',
          label: 'Ratesheet',
          type: 'dropdown',
          source: ['DEFAULT', 'BESPOKE'],
        },
      ],
      [anzsciCodes],
    );

    const onSaveClick = async () => {
      setErrors(initialErrors);
      const rows = removeEmptyRows({ rows: tableRef.current.hotInstance.getData() });
      const payload = convertRowsToPayload({ tableHeaders, rows, domain: tenantDomain });

      try {
        await createProposedTenant(tenantDomain, payload);
        showToastMessage(intl.formatMessage({ id: 'customerCreatedSuccessfully' }), MessageType.SUCCESS);
        onToggle();
        setFileRows();
        onSuccess && onSuccess();
      } catch ({ response }) {
        if (response?.data?.proposedTenants) {
          setErrors(response.data.proposedTenants);
          parseApiErrors({ tableHeaders, tableRef, errors: response.data.proposedTenants });
        }
        showToastMessage(intl.formatMessage({ id: 'customerCreatedError' }), MessageType.ERROR);
        tableRef.current.hotInstance.render();
      }
    };

    const onDrag = (event) => {
      event.preventDefault();
      event.stopPropagation();
      ['dragenter', 'dragover'].includes(event.type) ? setIsDragActive(true) : setIsDragActive(false);
    };

    const onFileChange = ({ target }) => {
      const csvContent = target.files[0];

      if (target.files) {
        fileReader.onload = function (event) {
          const text = event.target.result;
          csvFileToArray(text);
        };

        fileReader.readAsText(csvContent);
      }
    };

    const csvFileToArray = (csvText) => {
      const csvHeader = csvText.slice(0, csvText.indexOf('\n')).replaceAll('\r', '').split(',');
      const csvRows = csvText
        .slice(csvText.indexOf('\n') + 1)
        .replaceAll('\r', '')
        .split('\n');

      const rows = csvRows.map((row) => {
        const values = row.split(',');
        const result = csvHeader.reduce((content, header, index) => {
          content[header] = values[index];
          return content;
        }, {});
        return result;
      });

      setFileRows(rows);
    };

    const addNewRow = () => {
      tableRef.current.hotInstance.alter('insert_row');
    };

    const renderFileErrors = (row, index) =>
      Object.keys(row).length > 0 && (
        <React.Fragment>
          <span>Row #{index + 1}</span>
          <ul>
            {Object.keys(row).map((columnName) => (
              <li>
                {getLabelByColumn(tableHeaders, columnName)}: {row[columnName]}
              </li>
            ))}
          </ul>
        </React.Fragment>
      );

    return (
      <Drawer
        onClose={onToggle}
        titleId="addMultipleCustomers"
        className={classNames('light proposed-tenant-drawer', { closed: !open })}
        open={open}
        shouldCloseOnClickOutside={false}
        width="80vw"
      >
        {fileRows ? (
          <Column className="customers-container">
            <Row className="customers-container-header" justifyContentSpaceBetween alignItemsCenter>
              <Row alignItemsCenter>
                <Button onClick={addNewRow} className="add-row">
                  <FaPlus />
                  <FormattedMessage id="addRow" />
                </Button>
                {errors.length > 0 && (
                  <Popover
                    className="btn-solid-primary"
                    popoverClass="light drawer-popover large error-list"
                    popoverContent={<React.Fragment>{errors.map(renderFileErrors)}</React.Fragment>}
                    placement="right"
                  >
                    {({ onPopoverHandlerClick }) => (
                      <Button className="error-message" onClick={onPopoverHandlerClick}>
                        <FormattedMessage id="showDetailedErrors" />
                      </Button>
                    )}
                  </Popover>
                )}
              </Row>
              <Row>
                <Button onClick={() => setFileRows()} className="add-row">
                  <FaArrowLeft />
                  <FormattedMessage id="back" />
                </Button>
                <ConfirmationPopover
                  buttonLabelId="save"
                  confirmationMessageId="areYouSure"
                  className="btn-solid-primary save-button"
                  popoverClass="light"
                  type="button"
                  onConfirm={onSaveClick}
                />
              </Row>
            </Row>
            <HotTable
              ref={tableRef}
              colHeaders={tableHeaders.map(({ label }) => label)}
              data={fileRows}
              columns={tableHeaders}
              stretchH="all"
              startRows={3}
              preventOverflow="horizontal"
              rowHeights={30}
            />
          </Column>
        ) : (
          <Column
            className={classNames('draggable-container', { 'drag-active': isDragActive })}
            onDragEnter={onDrag}
            onDragLeave={onDrag}
          >
            {isDragActive ? (
              <FormattedMessage id="dropFileHere" tagName="h3" />
            ) : (
              <React.Fragment>
                <img src={imperiumLogo} alt="Imperium Logo" />
                <FormattedMessage id="uploadCsvFile" tagName="h3" />
                <p>
                  <FormattedMessage id="updateListReference" />{' '}
                  <a className="center-link forgot-password" href="/customers-sample.csv" target="_blank">
                    <FormattedMessage id="downloadLower" />
                  </a>
                </p>
                <label className="btn-solid-primary btn btn-default" htmlFor="file-customers">
                  <FormattedMessage id="upload" />
                </label>
              </React.Fragment>
            )}
            <input type="file" name="customers" id="file-customers" onChange={onFileChange} />
          </Column>
        )}
      </Drawer>
    );
  },
);

CreateMultiProposedTenantDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  tenantDomain: PropTypes.string.isRequired,
  anzsciCodes: PropTypes.arrayOf(PropTypes.shape),
};

const removeEmptyRows = ({ rows }) => rows.filter((row) => !row.every((cell) => [null, '', undefined].includes(cell)));

const convertRowsToPayload = ({ tableHeaders, rows, domain }) => {
  const proposedTenants = rows.map((row) => {
    return row.reduce((compiledRow, cell, index) => {
      compiledRow[tableHeaders[index].data] = cell;

      return compiledRow;
    }, {});
  });

  return { proposedTenants, domain };
};

const getIndexByColumn = (tableHeaders, columnName) => tableHeaders.findIndex(({ data }) => data === columnName);

const parseApiErrors = ({ tableHeaders, tableRef, errors }) => {
  errors.forEach((row, index) => {
    const errorKeys = Object.keys(row);

    if (errorKeys.length > 0) {
      errorKeys.forEach((columnName) => {
        const columnIndex = getIndexByColumn(tableHeaders, columnName);

        if (columnIndex >= 0) {
          tableRef.current.hotInstance.setCellMeta(index, columnIndex, 'valid', false);
        }
      });
    }
  });
};

const getLabelByColumn = (tableHeaders, columnName) => {
  const column = tableHeaders.find(({ data }) => data === columnName);

  return column && column.label;
};

const notEmptyValidator = (value, callback) => {
  const isEmpty = !value || String(value).length === 0;
  callback(!isEmpty);
};
